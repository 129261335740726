import "./App.scss";
import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { ConfigProvider, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { BroadcastChannel } from "broadcast-channel";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import API from "./config/API";

import FloatingButton from "./components/floatingButton";
import ProtectedRoute from "./utils/protectedRoute";
import ProtectedRouteAdmin from "./utils/protectedRouteAdmin";
import Header from "./components/header";
import Footer from "./components/footer";

import HomeSCreen from "./screens/homeScreen";
import ForgottPassword from "./screens/forgottPassword";
import SignupScreen from "./screens/signupScreen";
import SellerRegister from "./screens/sellerRegister";
import CorporateSeller from "./screens/sellerRegister/corporate";
import IndividualSeller from "./screens/sellerRegister/individual";
import LoginScreen from "./screens/loginScreens";
import ProductPage from "./screens/productScreen";
import ProductByCategory from "./screens/productByCat";
import ProductSearch from "./screens/productSearch";
import StoreHome from "./screens/StoreHome";
import ViewInvoice from "./screens/ViewInvoice";
import IndividualInfo from "./screens/sellerRegister/individual/individualInfo";
import StoreRedirectScreen from "./screens/storeDetails";
import SearchByStore from "./screens/searchStore";
import EmailVerify from "./screens/verifyMail";
import ResetPassword from "./screens/resetPassword";
import AccountDeactivation from "./screens/deactivateAccount";

import About from "./screens/About";
import BestPrice from "./screens/BestPrice";
import ServiceCenters from "./screens/ServiceCenters";
import Careers from "./screens/Careers";
import SolutionBar from "./screens/SolutionBar";
import BrandPromise from "./screens/BrandPromise";
import TermsandCond from "./screens/TermsandConditions";
import CheckWarrantyStatus from "./screens/CheckWarrantystatus";
import StoreLocator from "./screens/StoreLocator";
import EasyPaymentPlan from "./screens/EasyPaymentPlan";
import DisclaimerPolicy from "./screens/DisclaimerPolicy";
import ReturnAndExchangePolicy from "./screens/ReturnandExchangePolicy";
import BulkOrderEnquiries from "./screens/BulkOrderEnquiries";
import ReportFraud from "./screens/ReportFraud";
import Newsletter from "./screens/Newsletter";
import Blog from "./screens/Blog";
import TellUsMore from "./screens/TellUsMore";
import deliveryRestricted from "./screens/deliveryRestricted";
import Faq from "./screens/faq";
import NeedHelp from "./screens/needHelp";
import LiveRates from "./screens/liveRates";
import ShareScreen from "./screens/shareScreen";

import ProfileScreen from "./screens/profileScreen";
import CartScreen from "./screens/cartScreen";
import Checkout from "./screens/checkout";
import CheckoutSuccess from "./screens/checkoutSuccess";

import Admin from "./admin/route";

import { logout } from "./redux/slices/userSlice";
import { clearCart } from "./redux/slices/cartSlice";
import { clearStore } from "./redux/slices/storeSlice";
import RefundPolicy from "./screens/refundPolicy";
import BuyBackPolicy from "./screens/buyBackPolicy";
import ExchangePolicy from "./screens/exchangePolicy";
import ShippingPolicy from "./screens/shippingPolicy";
import CancellationPolicy from "./screens/cancellationPolicy";
import PrivacyPolicy from "./screens/privacy-policy";
import TermsOfService from "./screens/termsOfService";
import Contactus from "./screens/ContactUs";

function App() {
  const isLogin = useSelector((state: any) => state.User?.auth);
  const Role = useSelector((state: any) => state.User?.user?.data?.role ?? "");
  const Direction = useSelector((state: any) => state.Language.direction);
  let location = useLocation();
  location.pathname.startsWith("/str/");
  const logoutChannel = new BroadcastChannel("logout");
  message.config({ top: 100 });
  const dispatch = useDispatch();

  useEffect(() => {
    logoutChannel.addEventListener("message", (event) => {
      dispatch(logout(null));
      dispatch(clearCart(null));
      dispatch(clearStore(null));
    });

    return () => {
      logoutChannel.close();
    };
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <div dir={Direction}>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "DMSans-Regular",
              colorPrimary: API.COLOR,
              lineWidth: 1,
              controlOutlineWidth: 0,
              borderRadius: 2,
            },
            components: {
              Button: {
                fontSize: 16,
                fontWeight: "600",
              },
            },
          }}
        >
          {!location.pathname.startsWith("/str/") &&
          !location.pathname.startsWith("/share") ? (
            <Header />
          ) : null}
          <Routes>
            <Route index element={<HomeSCreen />} />;
            <Route path="/" element={<HomeSCreen />} />;
            <Route path="/home" element={<HomeSCreen />} />;
            <Route
              path="/login"
              element={!isLogin ? <LoginScreen /> : <Navigate to="/" replace />}
            />
            <Route
              path="/signup/:type"
              element={
                !isLogin ? <SignupScreen /> : <Navigate to="/" replace />
              }
            />
            ;
            <Route
              path="/forgott"
              element={
                !isLogin ? <ForgottPassword /> : <Navigate to="/" replace />
              }
            />
            <Route path="/share" element={<ShareScreen />} />;
            <Route path="/liveRates" element={<LiveRates />} />;
            <Route path="/seller" element={<SellerRegister />} />;
            <Route path="/slr/corporate" element={<CorporateSeller />} />;
            <Route path="/slr/individual" element={<IndividualSeller />} />;
            <Route path="/slr/individual_info" element={<IndividualInfo />} />
            <Route path="/products/category" element={<ProductByCategory />} />
            <Route path="/product/details" element={<ProductPage />} />;
            <Route path="/search" element={<ProductSearch />} />;
            <Route path="/store/:store/*" element={<SearchByStore />} />;
            <Route path="/str/:id/*" element={<StoreHome />} />
            <Route path="/about" Component={About} />
            <Route path="/Contactus" Component={Contactus} />
            <Route path="/best-price" Component={BestPrice} />
            <Route path="/service-centers" Component={ServiceCenters} />
            <Route path="/careers" Component={Careers} />
            <Route path="/solution-bar" Component={SolutionBar} />
            <Route path="/brand-promise" Component={BrandPromise} />
            <Route path="/terms-and-conditions" Component={TermsandCond} />
            <Route path="/warranty" Component={CheckWarrantyStatus} />
            <Route path="/store-locator" Component={StoreLocator} />
            <Route path="/easy-payment-plan" Component={EasyPaymentPlan} />
            <Route path="/disclaimer-policy" Component={DisclaimerPolicy} />
            <Route path="/return-policy" Component={ReturnAndExchangePolicy} />
            <Route path="/bulk-order" Component={BulkOrderEnquiries} />
            <Route path="/report-fraud" Component={ReportFraud} />
            <Route path="/newsletter" Component={Newsletter} />
            <Route path="/blog/:id" element={<Blog />} />
            <Route path="/tell-more" Component={TellUsMore} />
            <Route path="/delivery-restricted" Component={deliveryRestricted} />
            <Route path="/faq" Component={Faq} />
            <Route path="/contact" Component={NeedHelp} />
            <Route path="/reset-password/:token" Component={ResetPassword} />
            <Route path="/deactivate/:token" Component={AccountDeactivation} />
            <Route path="/invoice/:token" Component={ViewInvoice} />
            <Route path="/verify/email/:token" element={<EmailVerify />} />
            <Route path="/auth/check_store" element={<StoreRedirectScreen />} />
            <Route path="/refund-policy" Component={RefundPolicy} />
            <Route path="/buy-back-policy" Component={BuyBackPolicy} />
            <Route path="/exchange-policy" Component={ExchangePolicy} />
            <Route path="/shipping-policy" Component={ShippingPolicy} />
            <Route path="/cancellation-policy" Component={CancellationPolicy} />
            <Route path="/privacy-policy" Component={PrivacyPolicy} />
            <Route path="/terms-of-service" Component={TermsOfService} />
            {/*user routes login access needed*/}
            <Route element={<ProtectedRoute isSignedIn={isLogin} />}>
              <Route path="/cart" element={<CartScreen />} />;
              <Route path="/checkout" element={<Checkout />} />;
              <Route
                path="/checkoutsuccess/:type"
                element={<CheckoutSuccess />}
              />
              ;
              <Route path="/profile/*" element={<ProfileScreen />} />;
            </Route>
            {/*admin routes login access needed*/}
            <Route
              element={<ProtectedRouteAdmin isSignedIn={isLogin} role={Role} />}
            >
              <Route path="/auth/*" element={<Admin />} />;
            </Route>
            <Route path="*" element={<p>There's nothing here: 404!</p>} />
          </Routes>
          {!location.pathname.startsWith("/str/") &&
          !location.pathname.startsWith("/auth/") &&
          !location.pathname.startsWith("/share") ? (
            <Footer />
          ) : null}
          <FloatingButton />
        </ConfigProvider>
      </div>
    </I18nextProvider>
  );
}

export default App;
