import React from "react";
import { useContext } from "react";
import { SingleProductContext } from "../singleProductContext";
import { Col, Row } from "react-bootstrap";
import "../style.scss";
import RelatedItems from "./RelatedItems";
import { Divider, Image, Tabs, TabsProps } from "antd";
import { Rate } from "antd";
import Slider from "react-slick";
import { Imagesettings } from "./imageSliderSettings";
import ReviewTab from "./tabs/reviewTab";
import ProductDescription from "./tabs/productDescription";
import Zoom from "../components/reactZom";
import { Switch } from 'antd';
import { useState } from 'react';

function ProductCard() {
  const data = useContext(SingleProductContext);
  const productInfo = data?.data;  
  console.log("productInfo=====>><><><><",productInfo)
  const activeVariant = data?.activeVariant;
  console.log("=========>",data?.activeVariant)
  const reviewMeta = data?.reviewMeta;
  const reviewLoading = data?.reviewLoading;
  const [isSwitchEnabled, setIsSwitchEnabled] = useState(false);
  
  const checkImage = (data:any)=>{
    if(data?.status){
      return data
    }
    else{
      data.status = true
      data.productInfo = {
        image: productInfo?.image
      };
      return data
    }
  }
  const [ActiveVariant, setActiveVariant] = useState<any>(checkImage(data?.activeVariant));

  const handleSwitchChange = (checked:any) => {
    setIsSwitchEnabled(checked);
  };
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "About the product",
      children: <ProductDescription data={productInfo} />,
    },
    { 
      key: "2",
      label: "Reviews",
      children: <ReviewTab />,
    },
  ];
  const metalType: any = {
    gold24: "Gold 24",
    gold22: "Gold 22",
    gold21: "Gold 21",
    gold18: "Gold 18",
    silver: "Silver",
  };
  const basicInformation = [
    {
      name: "Product Type",
      detail: productInfo.title,
    },
    {
      name: "Category",
      detail: productInfo.categoryName.name,
    },
    {
      name: "Subcategory",
      detail: productInfo?.subCategoryName?.name,
    },
    {
      name: "Gender",
      detail: productInfo.gender,
    },
  ];
  const metalInformation = [
    {
      name: "Gross Weight ( g. )",
      detail: productInfo.weight,
    },
    {
      name: "Net Weight (g)",
      detail: !productInfo.net_weight ? "-" : productInfo.net_weight,
    },
    {
      name: "Stone Weight (g)",
      detail: !productInfo.stone_weight ? "-" : productInfo.stone_weight,
    },
    {
      name: "Metal Type",
      detail: metalType[productInfo.metal_type],
    },
  ];
  function capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  const getActiveVariant = (): string => {
    let variantInfo = "";
    if (activeVariant.status == true) {
      if (Array.isArray(activeVariant?.variant?.combination) == true) {
        activeVariant?.variant?.combination.forEach((item: any) => {
          variantInfo += ` ${capitalizeFirstLetter(item.value)}`;
        });
      }
    }
    return variantInfo;
  };
  const onChange = (key: string) => { };
  const changeImage = (data:any)=>{
    data.status = true
    setActiveVariant(data)
  }
  return (
    <div className="product-landing">
      <Row className="mx-0">
        <Col md={5}>
          <div className="productDetails-product-images">
            <div className="productDetails-image1">
            <div className="box-mobile">
              <Image
                className="rounded"
                width={"100%"}
                style={{ objectFit: "cover" }}
                src={
                  activeVariant?.status == true && activeVariant?.variant?.image
                    ? activeVariant?.variant?.image
                    : productInfo?.image
                }
                alt="image1"
              />
            </div>
              {/* <Image height={"70%"}
                className="rounded"
                width={"100%"}
                style={{ objectFit: 'contain' }}
                src={
                  activeVariant?.status == true && activeVariant?.variant?.image
                    ? activeVariant?.variant?.image
                    : productInfo?.image
                }
                alt="image1"
              /> */}
            </div>
            <div className="zoom">
              <Zoom activeVariant={ActiveVariant} productInfo={productInfo} />
            </div>
            <div
              style={{
                width: "100%",
                overflow: "hidden",
                marginTop: 10,
              }}
            >
              <Image.PreviewGroup>
                <Slider {...Imagesettings} className="cstmslider mt-md-3">
                  {productInfo?.productImages?.map(
                    (item: any, index: number) => (
                      <Image onClick={() => changeImage(item)} preview={false} style={{ height: "100px" }} className="productDetails-image2 product_screen_slider_image rounded  " src={item?.url} key={index}  />
                    )
                  )}
                </Slider>
              </Image.PreviewGroup>
            </div>
          </div>
        </Col>
        <Col md={7}>
          <div className="product-details ps-md-3">
            <div style={{display:"flex",justifyContent:"end"}}> <Switch defaultChecked onChange={handleSwitchChange} /></div>
         

            {isSwitchEnabled && (
        <>
          <h4 className="product-details-brand-name" style={{ letterSpacing: '4px' }}>
            {productInfo?.brand?.toUpperCase() ?? ""}
          </h4>
          <h1 className="productDetails-text1 productDetails-txt-bold">
            {productInfo?.name} {getActiveVariant()}
          </h1>
        </>
      )}
            <p className="">{productInfo?.description}</p>
            <div className="productDetails-details-row productDetails-margin-b-32">
              <span className="">
                {reviewMeta?.AvgRating > 0
                  ? reviewMeta?.AvgRating.toFixed(1)
                  : ""}
              </span>
              <Rate
                disabled   
                allowHalf
                value={reviewMeta?.AvgRating}
                className="productDetails-text3 ps-md-0"
              />
              <h6 className="productDetails-secondary-text productDetails-text4 productDetails-margin-h-6 ms-2">
                {reviewLoading == true
                  ? ""
                  : reviewMeta?.itemCount == null || reviewMeta?.itemCount == 0
                    ? "No Ratings"
                    : `${reviewMeta?.itemCount} ratings`}
              </h6>
            </div>

            <Tabs defaultActiveKey="1" items={items} onChange={onChange} className="ps-0" />
          </div>
        </Col>
        <div className="productDetails-margin-v-8 mt-3">
          <h6 className="productDetails-txt-bold">More Details</h6>
          <h6
            className="productDetails-secondary-text productDetails-text3"
            dangerouslySetInnerHTML={{
              __html: productInfo?.specifications,
            }}
          ></h6>
        </div>
        <div className="productDetails-margin-b-32" />

        <Divider />
        <Row className="productDetails-box">
          <Col xs={12}>
            <div className="productDetailsInfo-titil">Product Details</div>
          </Col>
          <Col sm={6} xs={12}>
            <div className="productInfo-titil">Basic Information</div>
            {basicInformation.map((item: any) => (
              <div className="productInfo-div1">
                <div className="productInfo-text">{item.name}</div>
                <div className="productInfo-text">{item.detail}</div>
              </div>
            ))}
          </Col>
          <Col sm={6} xs={12}>
            <div className="productInfo-titil">Metal Information</div>
            {metalInformation.map((item: any) => (
              <div className="productInfo-div1">
                <div className="productInfo-text">{item.name}</div>
                <div className="productInfo-text">{item.detail}</div>
              </div>
            ))}
          </Col>
        </Row>

        <RelatedItems />
      </Row>
    </div>
  );
}

export default ProductCard;
